import React, { useContext, useState } from "react";
import SessionDataContext from "../../../../contexts/SessionDataContext";
import EventContext from "../../../../contexts/EventContext";
import QuizzesScreen from "../../../screens/event/02/QuizzesScreen";
import useHandleResetEvent from "../../../hooks/useHandleResetEvent";
import EventQuiz from "../../../components/quizzes/EventQuiz";
import SelfieCacheContext from "../../../../contexts/SelfieCacheContext";
import useGetQuizStatus from "./useGetQuizStatus";
import useHandleCloseAllQuizzes from "./useHandleCloseAllQuizzes";
import EventNavigationController from "../EventNavigationController";
import EventChatController from "../EventChatController";
import useCountdownTimer from "../../../hooks/useCountdownTimer";
import useMonitorValue from "../../../hooks/useMonitorValue";
import EventSelfieController from "../EventSelfieController";
import DropEventPlayerOntoQuizController from "../DropEventPlayerOntoEventGroupController";
import useHostQuizMutation from "../../../../hooks/mutations/useHostQuizMutation";
import useNavigateToPlayQuiz from "../../../hooks/useNavigateToPlayQuiz";

const QuizzesController = ({ quizHostNotNeeded, onQuizHostNotNeededAck }) => {
  const { myEventHostId } = useContext(SessionDataContext);
  const {
    name,
    eventGroups,
    eventPlayers,
    publicEventId,
    allQuizzesCompleted,
    quizzesCloseAt,
  } = useContext(EventContext);
  const { getSelfieForParticipant } = useContext(SelfieCacheContext);
  const [showInviteModal, setShowInviteModal] = useState();
  const [showCloseAllQuizzesModal, setShowCloseAllQuizzesModal] = useState();
  const [showResetEventModal, setShowResetEventModal] = useState();
  const getQuizStatus = useGetQuizStatus();
  const handleCloseAllQuizzes = useHandleCloseAllQuizzes();
  const handleResetEvent = useHandleResetEvent(publicEventId);
  const [hostQuizMutation] = useHostQuizMutation();
  const navigateToPlayQuiz = useNavigateToPlayQuiz();

  const playersWithSelfies =
    eventPlayers &&
    eventPlayers.filter((player) => getSelfieForParticipant(player.id));

  const playerCount = playersWithSelfies ? playersWithSelfies.length : 0;

  const groupsWithQuizzes =
    eventGroups && eventGroups.filter((group) => group.quiz);

  useMonitorValue("quizzesCloseAt", quizzesCloseAt);

  const timeLeftBeforeQuizzesClose = useCountdownTimer(
    quizzesCloseAt,
    handleCloseAllQuizzes
  );

  const handleCoHostQuiz = (publicQuizId) => async () => {
    await hostQuizMutation({ variables: { publicQuizId } });
    await navigateToPlayQuiz();
  };

  return (
    <QuizzesScreen
      navigation={<EventNavigationController />}
      chat={<EventChatController />}
      eventTitle={name}
      participantCount={playerCount}
      isEventHost={myEventHostId}
      allQuizzesCompleted={allQuizzesCompleted}
      timeLeftBeforeQuizzesClose={timeLeftBeforeQuizzesClose}
      quizzes={
        groupsWithQuizzes &&
        groupsWithQuizzes.map((group) => {
          const quiz = group.quiz;
          return (
            <DropEventPlayerOntoQuizController
              key={group.name}
              eventGroup={group}
            >
              <EventQuiz
                quizName={group.name}
                hostName={group.eventGroupHost.name}
                status={getQuizStatus(quiz)}
                selfies={group.eventPlayers.map((eventPlayer) => (
                  <EventSelfieController
                    key={eventPlayer.id}
                    id={eventPlayer.id}
                    name={eventPlayer.name}
                  />
                ))}
                onCoHostQuiz={handleCoHostQuiz(quiz.publicId)}
              />
            </DropEventPlayerOntoQuizController>
          );
        })
      }
      quizHostNotNeeded={quizHostNotNeeded}
      onQuizHostNotNeededAck={onQuizHostNotNeededAck}
      publicEventId={publicEventId}
      onShowInviteModal={() => setShowInviteModal(true)}
      showInviteModal={showInviteModal}
      onCloseInviteModal={() => setShowInviteModal(false)}
      onShowCloseAllQuizzesModal={() => setShowCloseAllQuizzesModal(true)}
      showCloseAllQuizzesModal={showCloseAllQuizzesModal}
      onCloseAllQuizzes={handleCloseAllQuizzes}
      onCancelCloseAllQuizzes={() => setShowCloseAllQuizzesModal(false)}
      onShowResetEventModal={() => setShowResetEventModal(true)}
      showResetEventModal={showResetEventModal}
      onReset={handleResetEvent}
      onCancelReset={() => setShowResetEventModal(false)}
    />
  );
};

export default QuizzesController;
