import React, { useCallback, useContext } from "react";
import Navigation from "../../../../components/navigation/Navigation";
import getLogger from "../../../../../util/getLogger";
import useSetBroadcastingVideoMutation from "../../../../hooks/mutations/useSetBroadcastingVideoMutation";
import EventLobbyRoomContext from "../../../../contexts/EventLobbyRoomContext";
import useSetBroadcastingAudioMutation from "../../../../hooks/mutations/useSetBroadcastingAudioMutation";

const logger = getLogger("EventHostNavigationController");

const EventHostNavigationController = ({
  onShowSettingsModal,
  onShowLeaveModal,
  modal,
}) => {
  const { muted, setMuted, videoStopped, setVideoStopped } = useContext(
    EventLobbyRoomContext
  );
  const [setBroadcastingAudioMutation] = useSetBroadcastingAudioMutation();
  const [setBroadcastingVideoMutation] = useSetBroadcastingVideoMutation();

  const handleMicStateChange = useCallback(
    (value) => {
      logger.debug("in handleMicStateChange", value);
      if (value === "off") {
        logger.debug("muting");
        setMuted(true);
        if (videoStopped) {
          setBroadcastingAudioMutation({
            variables: {
              value: false,
            },
          });
        }
      } else if (value === "on") {
        logger.debug("unmuting");
        setMuted(false);
        if (videoStopped) {
          setBroadcastingAudioMutation({
            variables: {
              value: true,
            },
          });
        }
      }
    },
    [videoStopped]
  );

  const handleVideoStateChange = useCallback(
    (value) => {
      if (value === "off") {
        logger.debug("stopping video");
        setVideoStopped(true);
        if (muted) {
          setBroadcastingVideoMutation({
            variables: {
              value: false,
            },
          });
        } else {
          setBroadcastingAudioMutation({
            variables: {
              value: true,
            },
          });
        }
      } else if (value === "on") {
        logger.debug("starting video");
        setVideoStopped(false);
        setBroadcastingVideoMutation({
          variables: { value: true },
        });
      }
    },
    [muted]
  );

  return (
    <Navigation
      micState={muted ? "off" : "on"}
      videoState={videoStopped ? "off" : "on"}
      delayOnStopBroadcasting
      onMicStateChange={handleMicStateChange}
      onVideoStateChange={handleVideoStateChange}
      onShowSettingsModal={onShowSettingsModal}
      onShowLeaveModal={onShowLeaveModal}
      modal={modal}
    />
  );
};

export default EventHostNavigationController;
