import { gql, useMutation } from "@apollo/client";

const JOIN_EVENT_QUIZ = gql`
  mutation JoinEventQuiz($requestedQuizId: ID) {
    joinEventQuiz(requestedQuizId: $requestedQuizId) {
      quiz {
        id
        publicId
      }
    }
  }
`;

const useJoinEventQuizMutation = () => useMutation(JOIN_EVENT_QUIZ);

export default useJoinEventQuizMutation;
