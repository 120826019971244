import { gql, useMutation } from "@apollo/client";

const HOST_QUIZ = gql`
  mutation HostQuiz($publicQuizId: String!) {
    hostQuiz(publicQuizId: $publicQuizId) {
      quiz {
        id
        name
      }
    }
  }
`;

const useHostQuizMutation = () => useMutation(HOST_QUIZ);

export default useHostQuizMutation;
