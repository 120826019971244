import React from "react";
import styled from "styled-components";
import { PARAGRAPH_LARGE_BOLD, PARAGRAPH_NORMAL } from "../../typography";
import { WHITE } from "../../colors";
import PrimaryCard from "../elements/cards/PrimaryCard";
import CrowdLayout from "../layouts/CrowdLayout";
import MainButton from "../buttons/MainButton";

const StyledPrimaryCard = styled(PrimaryCard)`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 225px;
  padding: 30px 40px;
`;

const QuizName = styled.div`
  ${PARAGRAPH_LARGE_BOLD}
  color: ${WHITE};
  margin-bottom: 10px;
`;

const HostName = styled.div`
  ${PARAGRAPH_NORMAL}
  color: ${WHITE};
  margin-bottom: 5px;
`;

const WhatToShow = styled.div`
  ${PARAGRAPH_NORMAL}
  color: ${WHITE};
  margin-bottom: 15px;
`;

const CrowdWrapper = styled.div``;

const ButtonWrapper = styled.div`
  margin-top: 20px;
`;

const EventQuiz = ({ quizName, hostName, status, selfies, onCoHostQuiz }) => {
  return (
    <StyledPrimaryCard>
      <QuizName>{quizName}</QuizName>
      <HostName>Host: {hostName}</HostName>
      <WhatToShow>{status}</WhatToShow>
      <CrowdWrapper>
        <CrowdLayout individuals={selfies} />
      </CrowdWrapper>
      <ButtonWrapper>
        <MainButton onClick={onCoHostQuiz}>Co-Host</MainButton>
      </ButtonWrapper>
    </StyledPrimaryCard>
  );
};

export default EventQuiz;
