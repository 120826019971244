import React from "react";
import Droppable from "../../components/dnd/Droppable";
import useAssignToEventGroupMutation from "../../hooks/mutations/useAssignToEventGroupMutation";

const DropEventPlayerOntoEventGroupController = ({
  eventGroup,
  children,
  ...rest
}) => {
  const [assignToEventQuizMutation] = useAssignToEventGroupMutation();

  const isEventPlayerIdInThisEventGroup = (eventPlayerId) => {
    return eventGroup.eventPlayers.find(
      (eventPlayer) => eventPlayer.id === eventPlayerId
    );
  };

  return (
    <Droppable
      accept={"eventPlayer"}
      canDrop={(item) => !isEventPlayerIdInThisEventGroup(item.id)}
      onDrop={async (item) => {
        await assignToEventQuizMutation({
          variables: {
            eventPlayerId: item.id,
            eventGroupId: eventGroup.id,
          },
        });
      }}
      {...rest}
    >
      {children}
    </Droppable>
  );
};

export default DropEventPlayerOntoEventGroupController;
